import axios from "axios";
import { getBaseURL } from "./helper";
import { getPrefixKeys, storage } from "@/packages/passport";

const baseURL = getBaseURL();
const instance = axios.create({ baseURL });

export const setHeadersAuthInfo = async (headers: any) => {
  if (!headers) headers = {};
  const keys = getPrefixKeys();
  const [uid, bid, authToken, accessToken] = await Promise.all([
    storage.get(keys.uid),
    storage.get(keys.bid),
    storage.get(keys.authToken),
    storage.get(keys.accessToken),
  ]);

  headers["B-Id"] = bid;
  headers["U-Id"] = uid;
  headers["Auth-Token"] = authToken;
  headers["Access-Token"] = accessToken;
};

instance.interceptors.request.use(async (config) => {
  if (config.method === "post" && typeof config.data === "object") {
    config.headers["Content-Type"] = "application/json";
  }

  await setHeadersAuthInfo(config.headers);

  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.href = "/login";
    }
    return Promise.reject(error);
  },
);


export default instance;
