import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Image from "next/image";
import { IAppliedSuccessRef } from "../types";
import { Modal } from "@deeplang/dui";
import "../modules/invite-code-apply/index.css";
import Button, { ButtonType } from "@/ui/button";
import apiUser, {GuideUsage} from "@/api/user";

interface AppliedSuccessProps {}
export const AppliedSuccess = forwardRef<
  IAppliedSuccessRef,
  AppliedSuccessProps
>((props: AppliedSuccessProps, ref) => {
  const [showModal, setShowModal] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      apiUser.hasShowGuide({sceneType: GuideUsage.NewerGuide}).catch((err) => {
        console.log(err);
      });
      setShowModal(true);
    },
  }));

  return (
    <>
      <Modal
        centered
        open={showModal}
        onCancel={() => {
          setShowModal(false);
        }}
        width={417}
        wrapClassName="invite-code-modal"
        footer={<></>}
      >
        <img
          className="invite-img"
          src={`https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/plugins/assets/invite_img.jpeg`}
          alt=""
        />
        <div className="invite-container">
          <p className="invite-text">🎉 欢迎加入</p>
          <p className="invite-content">
            欢迎你加入灵狗产品内测，扫描二维码添加小助手微信获取更多信息
          </p>
        </div>
        <div className="flex items-center gap-2 justify-center">
          <Image
            width={139}
            height={133}
            alt="小助手"
            src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/bg/wechathelper.jpeg"
          />
        </div>
        <div className="pl-4 pb-0 pr-4 flex items-center gap-2 justify-center">
          <Button
            style={{
              padding: "6px 18px 6px 18px",
              width: "140px",
              fontSize: "16px",
              letterSpacing: "0.8px",
            }}
            type={ButtonType.Primary}
            onClick={() => {
              setShowModal(false);
            }}
          >
            开始探索
          </Button>
        </div>
      </Modal>
    </>
  );
});

AppliedSuccess.displayName = "AppliedSuccess";
