import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { NoApprovedTesting } from "@/modules/personal-center/apply-test/components/no-approved-testing";
import { eventBus, EventMessage } from "@/utils/event-bus";

const PrivateTestPermission = () => {
  const router = useRouter();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    eventBus.on(EventMessage.SHOW_NO_PERMISSION, onShow);
    return () => {
      eventBus.off(EventMessage.SHOW_NO_PERMISSION, onShow);
    };
  }, []);

  const handleCancel = () => {
    setOpen(false);
  };

  const onClick = () => {
    router.push("/account");
  };

  const onShow = () => {
    setOpen(true);
  };

  return (
    <NoApprovedTesting
      showNoApprovedModal={open}
      handleNoApprovedModalCancel={handleCancel}
      handleClick={onClick}
      closeable={window.location.pathname.indexOf('reader') > -1}
    />
  );
};

export default PrivateTestPermission;
