import { useAnyHooksStore } from "@/provider/any-hooks-layout";
import { useAppLayout } from "@/provider/app-layout";
import { to } from "@deeplang/shared";
import { UserSetting } from "./useUserSetting";

export function useInitUserSetting() {
  const { updateUserSetting } = useAppLayout();
  const { request } = useAnyHooksStore();

  const getUserSetting = async () => {
    const [err, result] = await to(
      request<UserSetting>({
        url: "/api/users/setting/info",
        method: "GET",
      }),
    );

    if (err) {
      return;
    }
    if (!result) {
      return;
    }

    updateUserSetting(result);
  };

  return {
    getUserSetting,
  };
}
