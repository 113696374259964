import { useContext } from "react";
import { GuideContext } from "./provider";
import { ThirdParty } from "../types";
import useStore from "./all-store";

export * from "./provider";

type CommonType = ThirdParty & ReturnType<typeof useStore>;
type State = {
  [P in keyof CommonType]: CommonType[P];
};
export const useGuideStore = (): State => {
  const state = useContext(GuideContext);

  if (state === null) {
    throw new Error("useGuideStore must be used within a GuideProvider");
  }

  const { thirdParty, ...rest } = state;

  return {
    ...thirdParty,
    ...rest,
  };
};
