"use client";

import { useEffect, useState } from "react";
import { useRouter } from "next/navigation";

const MigrateJump = () => {
  const router = useRouter();
  const [second, setSecond] = useState(5);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecond((prev: number) => {
        if (prev === 0) {
          router.replace("https://lingowhale.com/home");
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[99999] flex justify-center items-center">
      <div className="w-[420px] h-[150px] flex flex-col justify-center items-center rounded-lg bg-white p-4 text-lg text-black leading-8">
      Hi 主人，从今天起将由小伙伴「🐳语鲸」接替我的工作。{second} 秒后前往自动前往语鲸，开启高效阅读~
      </div>
    </div>
  );
};

export default MigrateJump;
