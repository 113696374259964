import { guideBasic } from "./guide";

export default function useStore() {
  const addGuide = guideBasic.addGuide;

  const nextGuide = guideBasic.nextGuide;

  const removeGuide = guideBasic.removeGuide;

  const getCurrentGuide = guideBasic.getCurrentGuide;

  const iteratorShowGuide = guideBasic.iteratorShowGuide;

  const getGuideById = guideBasic.getGuideById;

  return {
    getCurrentGuide,
    removeGuide,
    addGuide,
    nextGuide,
    iteratorShowGuide,
    getGuideById,
  };
}
