import request from "../utils/request";

export interface ILikeParams {
  entryId: string;
  entryType: string;
}

export enum GuideUsage {
  NewerGuide = 1, // 场景类型，1 插件新手引导
  PDFGuide = 2, // 场景类型 2 PDF上传新手引导
}
export interface IHasShowGuideRequest {
  sceneType: GuideUsage;
}

export interface IUsersApi {
  like: (data: ILikeParams) => Promise<void>;
  dislike: (data: ILikeParams) => Promise<void>;
  getBenefitsInfo: () => Promise<void>;
  hasShowGuide: (data: IHasShowGuideRequest) => Promise<void>;
}

const api: IUsersApi = {
  like(params) {
    const data = { ...params, action: 0 };
    return request({
      url: "/api/plugin/users/like/add",
      method: "post",
      data,
    });
  },
  dislike(params) {
    const data = { ...params, action: 1 };
    return request({
      url: "/api/plugin/users/like/add",
      method: "post",
      data,
    });
  },
  getBenefitsInfo() {
    return request({
      url: "/api/plugin/users/benefits/list",
      method: "GET",
    });
  },
  hasShowGuide(params: IHasShowGuideRequest) {
    return request('/api/plugin/newbieGuide', {
      method: 'POST',
      data: {
        scene_type: params.sceneType,
      },
    });
  },
};

export default api;
