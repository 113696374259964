import { GuideComponentType, mergeSort, singleton } from "@deeplang/shared";
import { get } from "lodash-es";

export interface Guide {
  id: number;
  priority: number;
  weight: number;
  // pending：表示等待是否打开的状态，rejected，表示用户主动关闭，resolved 表示从后端拉取时为 finish 的状态
  status: "pending" | "rejected" | "resolved";
  show: () => void;
  disabled?: boolean;
}
export interface GuideMapValue extends Guide {
  show: () => void;
}

class GuideBasic {
  private _guides: Guide[] = [];

  private _guideMap = new Map<number, GuideMapValue>();
  private _currentIndex = 0;

  get guides() {
    return this._guides;
  }

  clear = () => {
    this._guideMap.clear();
    this._guides.length = 0;
  };

  addGuide = (
    type: GuideComponentType,
    priority: number,
    weight: number,

    status: Guide["status"],
    gid: number,
    show = () => {},
    disabled = false,
  ) => {
    // 存储 guide 便于查找
    const guide = this._guideMap.get(gid);
    if (!guide) {
      const oneGuide = {
        id: gid,
        priority,
        show,
        weight,
        status,
        disabled,
      };
      this._guideMap.set(gid, oneGuide);
      this._guides.push(oneGuide);
    } else {
      Object.assign(guide, {
        priority,
        weight,
        status,
        show,
        disabled,
      });
    }

    // 先根据优先级排序
    // 优先级主要排序，不能确定 sort 是否稳定
    this._guides = mergeSort(this._guides, "priority", get);

    // 权重作为次要排序
    this._guides = mergeSort(this._guides, "weight", get);
  };

  // 每个引导框执行打开时，都需要全量跑一次所有的引导框，找到第一个 'pending' 的引导框。
  // 去确认是否有更高优先级的引导框处于 pending 状态。
  iteratorShowGuide = () => {
    let foundGuide = null;
    for (let i = 0; i < this._guides.length; i++) {
      const guide = this._guides[i];
      if (!guide) {
        continue;
      }

      if (guide.status === "pending" && guide.disabled !== true) {
        this._currentIndex = i;
        foundGuide = guide;
        break;
      }
    }

    if (!foundGuide) {
      return;
    }

    const guide = this._guideMap.get(foundGuide.id);
    if (!guide) {
      return;
    }

    guide.show();
  };

  nextGuide = () => {
    this._currentIndex++;

    if (this._currentIndex >= this._guides.length) {
      this._currentIndex = 0;
      return;
    }
    const currentGuide = this._guides[this._currentIndex];

    if (!currentGuide) {
      return;
    }
    if (currentGuide.status !== "pending" || currentGuide.disabled === true) {
      // 如果发现下一个 guide 不是 pending 的状态，或者下一个 guide 被禁用了，则继续找下一个
      this.nextGuide();
      return;
    }
    const guide = this._guideMap.get(currentGuide.id);

    guide?.show();
  };

  removeGuide = (id: number) => {
    // 从 guideMap 中删除
    this._guideMap.delete(id);
    // 根据 id，从 guides 中删除
    let findIndex = -1;
    for (let i = 0; i < this._guides.length; i++) {
      const guide = this._guides[i];
      if (!guide) {
        continue;
      }

      if (id === guide.id) {
        findIndex = i;
        break;
      }
    }

    if (findIndex === -1) {
      return;
    }

    this._guides.splice(findIndex, 1);
  };

  getCurrentGuide = () => {
    if (this._guides.length > 0) {
      return this._guides[this._currentIndex];
    }

    return null;
  };

  getGuideById = (id: number) => {
    return this._guideMap.get(id);
  };
}

const SingleGuideBasic = singleton(GuideBasic);

export const guideBasic = new SingleGuideBasic();
