import { usePassport, ModalShowType, IUserInfo } from "@/packages/passport";
import { eventBus, EventMessage } from "@/utils/event-bus";
import { InvitationTestStatus } from "@deeplang/shared";

function useUserLoginPermission() {
  const passport = usePassport();
  const checkUser = (callback?: () => void) => {
    console.log("hadLogin ==>", passport.state.hadLogin);
    if (!passport.state.hadLogin && passport.state.loading) {
      passport.show(ModalShowType.Login, (userInfo?: IUserInfo) => {
        if (userInfo?.id) {
          const { invitedStatus } = userInfo;
          // 没有内测权限，发送展示没有内测权限弹窗事件
          if (invitedStatus !== InvitationTestStatus.HasRequestAndApproved) {
            eventBus.emit(EventMessage.SHOW_NO_PERMISSION);
            return;
          }
          callback && callback();
        }
      });
    } else {
      callback && callback();
    }
  };
  return {
    checkUser,
  };
}

export default useUserLoginPermission;
