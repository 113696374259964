"use client";
import { useEffect, useRef, useState } from "react";
import {
  DeepLogin,
  DeepGuardProvider,
  ProductSource,
  deepGuardConfig,
  usePassport,
  PassportEvent,
  PassportEventMsg,
  postLoginMessage,
  ProductSubSource,
} from "@/packages/passport";
import { IPassportConfig } from "@/packages/passport/config";
import { useSearchParams, useRouter } from "next/navigation";
import { ConfigProvider } from "@deeplang/dui";
import { useApplyTestWithLogin } from "@/client/hooks/apply-test-with-login";
import useUserLoginPermission from "@/client/hooks/useUserLoginPermission";
import { PostMessage } from "@/packages/passport/utils";
import { AnyHooksProvider } from "@/provider/any-hooks-layout";
import request from "@/utils/request";
import fileRequest from "@/utils/file-request";
import buryHatchet from "@/batch";
import { useGuide } from "@/client/hooks/useGuide";
import PrivateTestPermission from "@/components/private-test-permission";
import { eventBus, EventMessage } from "@/utils/event-bus";
import { InvitationTestStatus, materialAdapter } from "@deeplang/shared";
import { IAppliedSuccessRef } from "@/modules/personal-center/apply-test/types";
import { AppliedSuccess } from "@/modules/personal-center/apply-test/components/AppliedSuccess";
import { GuideProvider } from "@/modules/guide";
import { downloadFileUrl } from "@/config";
import { ProductName } from "@/packages/passport/constants/enum";
import apiUser from "@/packages/passport/api/user";
import to from "@/utils/await-to";
import { setAuthInfo, setHeadersAuthInfo } from "@/packages/passport/utils";
import { useInitUserSetting } from "@/client/hooks/useInitUserSetting";
import PubSub from "pubsub-js";

type PassportWrapperProps = {
  children: React.ReactNode;
};
export interface ICheckIsSafe {
  bid?: string;
  uid?: string;
  authToken?: string;
  accessToken?: string;
}

export interface ICheckIsSafe {
  bid?: string;
  uid?: string;
  authToken?: string;
  accessToken?: string;
}

const options: IPassportConfig = {
  env: (process.env.NEXT_PUBLIC_ENV as any) || "prod",
  source: ProductSource.Lingo,
  subSource: ProductSubSource.LingoWebsite,
  productName: ProductName.Lingo,
  appId: "wxa2dfa2f0ecb9921b",
  mode: "modal",
  config: {
    privacyPolicyUrl: "/privacy",
    userProtocolUrl: "/service",
    copyrightUrl: "/help/settlement",
  },
};

deepGuardConfig.init(options);
buryHatchet.init("sensors");
materialAdapter.initial(downloadFileUrl);

function LoginWrapper() {
  const passport = usePassport();
  const { getApplyingTestStatus } = useApplyTestWithLogin();
  const { getGuideList } = useGuide();
  const { checkUser } = useUserLoginPermission();
  const router = useRouter();
  const appliedSuccessModalRef = useRef<IAppliedSuccessRef | null>(null);
  const [hasClosePasswordSetting, setHasClosePasswordSetting] = useState(false);
  const params = useSearchParams();
  const { getUserSetting } = useInitUserSetting();

  const authCheck = async () => {
    try {
      await passport?.getUserinfo();
    } catch (error) {
      console.log("PassportWrapper----authCheck", error);
    }
  };

  const loginCallback = (data: any) => {
    const { error, result } = data;
    if (error) return;
    postLoginMessage(PostMessage.PC_LOGIN, {
      data: result,
      env: process.env.NEXT_PUBLIC_ENV,
    });

    buryHatchet.login(result.uid);
    buryHatchet.setProfile({
      uid: result.uid,
    });
  };

  const checkUserState = (data: { callback?: () => void }) => {
    const { callback } = data;
    checkUser(callback);
  };

  const loginExpired = () => {
    router.replace("/");
  };

  const handlePasswordSetting = () => {
    setHasClosePasswordSetting(true);
  };

  const checkIsSafe = async () => {
    //     const [err, result] = await to(
    //       apiUser.checkLoginInfoSecurity({
    //         bid: params.get("bid"),
    //         uid: params.get("uid"),
    //         auth_token: params.get("authToken"),
    //         access_token: params.get("accessToken"),
    //       }),
    //     );
    const paramsCheck = {
      uid: params.get("uid") || "",
      b_id: params.get("bid") || "",
      auth_token: params.get("authToken") || "",
      access_token: params.get("accessToken") || "",
    };
    setAuthInfo(paramsCheck);
    await setHeadersAuthInfo("");

    const [err2, result2] = await to(apiUser.getUserinfo());

    if (err2) {
      return Promise.reject(err2);
    }
  };
  useEffect(() => {
    if (params.get("uid")) {
      checkIsSafe();
    }
    authCheck();

    PassportEvent.on(PassportEventMsg.LOGIN, loginCallback);
    PassportEvent.on(PassportEventMsg.LOGIN_EXPIRED, loginExpired);
    PassportEvent.on(PassportEventMsg.PASSWORD_SETTING, handlePasswordSetting);
    eventBus.on(EventMessage.CHECK_USER, checkUserState);

    return () => {
      PassportEvent.off(PassportEventMsg.LOGIN, loginCallback);
      PassportEvent.off(PassportEventMsg.LOGIN_EXPIRED, loginExpired);
      PassportEvent.off(
        PassportEventMsg.PASSWORD_SETTING,
        handlePasswordSetting,
      );
      eventBus.off(EventMessage.CHECK_USER, checkUserState);
    };
  }, []);

  const whenLogin = async () => {
    await getApplyingTestStatus();
    getGuideList();
    getUserSetting();
  };

  useEffect(() => {
    if (
      passport.userInfo.invitedStatus ===
        InvitationTestStatus.HasRequestAndApproved &&
      passport.userInfo.login_type === "regist" &&
      hasClosePasswordSetting
    ) {
      appliedSuccessModalRef.current?.show();
    }
  }, [
    passport.userInfo.invitedStatus,
    passport.userInfo.login_type,
    hasClosePasswordSetting,
  ]);

  useEffect(() => {
    if (passport.state.hadLogin && !passport.state.loading) {
      buryHatchet.registerPublicProperties({
        isLogin: true,
        uid: passport.userInfo.uid,
      });

      whenLogin();
    }
  }, [passport.state.hadLogin, passport.state.loading]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2d2d2d",
        },
        components: {
          Tabs: {
            inkBarColor: "#2d2d2d",
            itemActiveColor: "#2d2d2d",
            itemColor: "#7a7b7b",
            itemHoverColor: "#2d2d2d",
            itemSelectedColor: "#2d2d2d",
            horizontalItemPadding: "8px 0",
          },
        },
      }}
    >
      <DeepLogin />
      <PrivateTestPermission />
      <AppliedSuccess ref={appliedSuccessModalRef} />
    </ConfigProvider>
  );
}

function PassportWrapper({ children }: PassportWrapperProps) {
  return (
    <AnyHooksProvider
      defaultParams={{
        thirdParty: {
          request,
          fileRequest,
          buryHatchet,
          pubSub: PubSub,
        },
      }}
    >
      <DeepGuardProvider {...options}>
        <LoginWrapper />
        <GuideProvider defaultParams={{ thirdParty: {} }}>
          {children}
        </GuideProvider>
      </DeepGuardProvider>
    </AnyHooksProvider>
  );
}

export default PassportWrapper;
