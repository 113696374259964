import { BATCH } from "@/batch/type";
import { GetApplyInvitationStatusResponse } from "@/modules/personal-center/apply-test/types";
import { usePassport } from "@/packages/passport";
import { IUserInfo } from "@/packages/passport/type";
import { useAnyHooksStore } from "@/provider/any-hooks-layout";
import request from "@/utils/request";

export function useApplyTestWithLogin() {
  const passport = usePassport();
  const { buryHatchet } = useAnyHooksStore();

  const getApplyingTestStatus = async (userInfoFromRequest?: IUserInfo) => {
    try {
      const result = await request<GetApplyInvitationStatusResponse>({
        url: "/api/plugin/users/inviteTest/status",
      });
      if (!result) {
        return;
      }

      passport.updateState({
        userInfo: {
          ...(userInfoFromRequest ? userInfoFromRequest : passport.userInfo),
          invitedStatus: result.status,
        },
      });
      buryHatchet.registerPublicProperties({
        invitation_test: result.status,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return {
    getApplyingTestStatus,
  };
}
