import { createContext } from "react";
import { Maybe } from "@deeplang/shared";
import { IGuideProps } from "../types";
import useStore from "./all-store";

export interface Options {
  children?: React.ReactNode;
  defaultParams: IGuideProps;
}

export const GuideContext =
  createContext<Maybe<IGuideProps & ReturnType<typeof useStore>>>(null);

export const GuideProvider = (options: Options) => {
  const { children, defaultParams } = options;
  const store = useStore();

  return (
    <GuideContext.Provider value={{ ...defaultParams, ...store }}>
      {children}
    </GuideContext.Provider>
  );
};
