import "./no-approved.css";
import { NoApprovedTestingV2 } from "./NoApprovedTestingV2";

interface NoApprovedProps {
  showNoApprovedModal: boolean;
  handleNoApprovedModalCancel: () => void;
  handleClick?: () => void;
  closeable?: boolean;
}

export function NoApprovedTesting(props: NoApprovedProps) {
  const {
    showNoApprovedModal,
    handleNoApprovedModalCancel,
    closeable = true,
  } = props;

  return (
    <NoApprovedTestingV2
      showNoApprovedModal={showNoApprovedModal}
      handleNoApprovedModalCancel={handleNoApprovedModalCancel}
      closeable={closeable}
    />
  );
}
