import {
  AcknowledgeGuideRequest,
  GetGuideListResponse,
  GuideItem,
  UserGuideId,
} from "@deeplang/shared";
import request from "@/utils/request";
import { useAppLayout } from "@/provider/app-layout";
import { cloneDeep } from "lodash-es";

export function useGuide() {
  const { updateState, state, setState } = useAppLayout();
  const getGuideList = async () => {
    try {
      const res = await request<GetGuideListResponse>("/api/guide/list", {
        method: "POST",
      });
      if (!res) {
        updateState({
          ...state,
          userGuideList: [],
          hasGotGuideList: true,
        });
        return;
      }
      updateState({
        ...state,
        userGuideList: res,
        hasGotGuideList: true,
      });
    } catch {
      updateState({
        ...state,
        userGuideList: [],
        hasGotGuideList: true,
      });
    }
  };

  const getSpecificGuideById = (
    id: UserGuideId,
    guideList?: GuideItem[],
  ): GuideItem | null => {
    const list = guideList ? guideList : state.userGuideList;
    const guide = list.find((guide) => guide.id === id);

    if (!guide) {
      return null;
    }

    return guide;
  };

  const acknowledgeGuide = async (props: AcknowledgeGuideRequest) => {
    const { guide_id } = props;
    try {
      await request("/api/guide/finish", {
        method: "POST",
        data: {
          guide_id,
        },
      });

      const temp = cloneDeep(state.userGuideList);
      temp.forEach((guide) => {
        if (guide.id === guide_id) guide.is_finished = true;
      });
      updateState({
        ...state,
        userGuideList: temp,
      });
    } catch (e) {
      console.log(e);
    }
  };

  return {
    hasGotGuideList: state.hasGotGuideList,
    userGuideList: state.userGuideList,
    getGuideList,
    getSpecificGuideById,
    acknowledgeGuide,
  };
}
