import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Button!=!/app/node_modules/.pnpm/antd@5.19.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=ConfigProvider,Select!=!/app/node_modules/.pnpm/antd@5.19.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/.pnpm/antd@5.19.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Switch!=!/app/node_modules/.pnpm/antd@5.19.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Tooltip!=!/app/node_modules/.pnpm/antd@5.19.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/app/apps/lingoreader/src/app/globals.css");
import(/* webpackMode: "eager" */ "/app/apps/lingoreader/src/auth/PassportWrapper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lingoreader/src/components/migrate-jump/index.tsx");
import(/* webpackMode: "eager" */ "/app/apps/lingoreader/src/provider/app-layout/provider.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@ant-design+nextjs-registry@1.0.0_@ant-design+cssinjs@1.21.0_react-dom@18.2.0_react@18.2.0__r_fseakrqfuq2lnw2f3rn7o6mblu/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/antd@5.19.4_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.8/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.1.0_@babel+core@7.25.2_react-dom@18.2.0_react@18.2.0__react@18.2.0_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/ActionBar/ActionBar.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DButton/index.css");
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/katex@0.16.11/node_modules/katex/dist/katex.min.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DDrawer/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DFullScreen/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DModal/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DSteps/Items.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DTooltip/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/ImageViewer/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/ImgViewer/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/OperationBar/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DLButton/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/IconWrapper/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/OperateIcons/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DSteps/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DSwitch/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/OutlineItem/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/OutlineList/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/output.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/DSelect/index.css");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/SearchModal/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/TableViewer/index.js");
import(/* webpackMode: "eager" */ "/app/packages/dui/dist/Toast/Toast.js");
import(/* webpackMode: "eager" */ "/app/packages/icons/dist/index.css");
import(/* webpackMode: "eager" */ "/app/packages/icons/dist/icons-demo/index.css")