import Image from "next/image";
import { Modal } from "@deeplang/dui";

interface NoApprovedProps {
  showNoApprovedModal: boolean;
  handleNoApprovedModalCancel: () => void;
  closeable?: boolean;
}

export function NoApprovedTestingV2(props: NoApprovedProps) {
  const {
    showNoApprovedModal,
    handleNoApprovedModalCancel,
    closeable = true,
  } = props;

  return (
    <>
      <Modal
        open={showNoApprovedModal}
        onCancel={handleNoApprovedModalCancel}
        footer={null}
        width={417}
        centered
        closeIcon={closeable}
        wrapClassName="no-approved-modal"
      >
        <div className="pb-[26px]">
          <div className="pt-0 px-4 pb-4 flex-col flex justify-center items-center text-center text-base tracking-[0.8px]">
            <span>当前内测名额紧张</span>
            <span className="whitespace-nowrap">
              请扫描二维码添加小助手微信了解更多
            </span>
          </div>

          <div className="flex justify-center items-center">
            <Image
              width={139}
              height={133}
              alt="小助手"
              src="https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/lingoreader/bg/lingocustomerservice.jpeg"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
